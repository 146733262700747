import { RouterProvider } from "react-router-dom";
import { Router } from "@remix-run/router";
import {
  AdapterMoment,
  WhmLocalizationProvider,
  WhmSnackbarProvider,
} from "@securitize/reactjs-whm";
import MFEContainer from "./containers/MFEContainer";
import { UserInfo } from "./types/UserInfo";
import UserContext from "./contexts/UserContext";
import { MixPanelType } from "./types/mixpanel.types";

export interface Props {
  language: string;
  userInfo: UserInfo;
  router: Router;
  MixPanel: MixPanelType;
  basePath?: string;
  onUpdate?: () => void;
}

function App({
  language,
  userInfo,
  router,
  MixPanel,
  basePath,
  onUpdate,
}: Props) {
  return (
    <MFEContainer
      language={language}
      MixPanel={MixPanel}
      basePath={basePath}
      onUpdate={onUpdate}
    >
      <UserContext userInfo={userInfo}>
        <WhmSnackbarProvider>
          <WhmLocalizationProvider dateAdapter={AdapterMoment}>
            <RouterProvider router={router} />
          </WhmLocalizationProvider>
        </WhmSnackbarProvider>
      </UserContext>
    </MFEContainer>
  );
}

export default App;
