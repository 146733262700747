import React, { useState } from 'react';
import {
  useMediaQuery,
  WhmBox,
  WhmDialog,
  WhmDialogContent,
  WhmDialogActions,
  WhmIconButton,
  WhmTypography,
  WhmButton,
  Theme,
} from "@securitize/reactjs-whm";
import { useTheme } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import verifyProfile from '../../../assets/verify-profile.svg';
import useTranslations from "../../../hooks/useTranslations";
import { translationKeys } from "../../../contexts/I18nContext";
import { REDIRECT } from "./Inputs/constants";

const VerifyProfileModal = () => {
  const { getTranslation: t } = useTranslations();
  const redirectUrl = REDIRECT.KYC_URL.replaceAll('{REDIRECT_URL}', window.location.href);
  const [isOpen, setIsOpen] = useState(true);

  const redirectToKycProcess = () => {
    window.location.href = redirectUrl;
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const whmTheme = useTheme();
  const isMobile = useMediaQuery(whmTheme.breakpoints.down("xl"));

  return (
    <WhmDialog
      open={isOpen}
      onClose={onClose}
      fullScreen={isMobile}
      {...(!isMobile && { maxWidth: "xs" })}
      {...(isMobile && { maxWidth: "lg" })}
      aria-labelledby="verify-profile-modal-title"
      aria-describedby="verify-profile-modal-description"
    >
      <WhmIconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme: Theme) => theme.palette.grey[600],
        }}
      >
        <CloseIcon />
      </WhmIconButton>

      <WhmDialogContent
        sx={{
          textAlign: 'center',
          pt: 5,
          pb: 3,
          px: 3,
        }}
      >
        <WhmBox sx={{ marginTop: '1rem', marginBottom: '1rem' }}>
          <img
            src={verifyProfile}
            alt="Verify Profile"
            style={{ width: 120, height: 120 }}
          />
        </WhmBox>

        <WhmTypography
          id="verify-profile-modal-title"
          variant="h5"
          sx={{ mb: 2 }}
        >
          {t(translationKeys.FORM_HEADER_TITLE)}
        </WhmTypography>

        <WhmTypography
          id="verify-profile-modal-description"
          variant="caption"
          sx={{ mb: 3 }}
        >
          {t(translationKeys.FORM_VERIFY_PROFILE_MODAL_TEXT)}
        </WhmTypography>
      </WhmDialogContent>

      <WhmDialogActions sx={{ justifyContent: isMobile ? 'flex-end' : 'center', pb: 3 }}>
        <WhmButton
          variant="contained"
          color="primary"
          onClick={redirectToKycProcess}
          sx={{ mr: 2 }}
        >
          {t(translationKeys.FORM_VERIFY_PROFILE_MODAL_BUTTON)}
        </WhmButton>
        <WhmButton
          variant="text"
          color="primary"
          onClick={onClose}
        >
          {t(translationKeys.FORM_VERIFY_PROFILE_MODAL_CANCEL)}
        </WhmButton>
      </WhmDialogActions>
    </WhmDialog>
  );
};

export default VerifyProfileModal;
