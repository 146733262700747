import { useEffect, useState } from "react";
import { WhmButton, WhmStack, useMediaQuery } from "@securitize/reactjs-whm";
import { useNavigate, useLocation } from "react-router";
import { useTheme } from "@mui/material";
import useInvestorInfo from "../../hooks/useInvestorInfo";
import W9TaxForm from "./components/W9Form";
import W8BENTaxForm from "./components/W8BENForm";
import W8BENETaxForm from "./components/W8BENEForm";
import FormTypeSelector from "./components/FormTypeSelector";
import useTranslations from "../../hooks/useTranslations";
import { translationKeys } from "../../contexts/I18nContext";
import useMixPanel, { TrackingType } from "../../hooks/useMixPanel";
import { TaxFormType } from "../../types/documents.interface";
import { useMFEContext } from "../../contexts/MFEContext";
import FormHeader from "./components/FormHeader";
import { Close } from "@mui/icons-material";
import { isTaxDocument } from "../../common/taxForms.utils";
import { getDocuments } from "../../data/rest/documents";
import { ErrorPage } from "../../components/shared/ErrorPage";
import VerifyProfileModal from './components/VerifyProfileModal'

const TaxForm = () => {
  const {
    isLoading,
    hasUSTax,
    hasNoTax,
    hasForeignTax,
    isEntity,
    userInfo,
    investorInfo,
    isVerified,
    error: erroredInvestor,
  } = useInvestorInfo();
  const [currentForm, setCurrentForm] = useState<TaxFormType | null>(null);
  const [error, setError] = useState<boolean>(false);
  const { getTranslation: t } = useTranslations();
  const track = useMixPanel();
  const navigate = useNavigate();
  const theme = useTheme();
  const { basePath } = useMFEContext();
  const { pathname } = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    track(TrackingType.TA_PAYOUTS_OPENED_TAX_FORM_PROCESS);

    async function validateTaxForms() {
      try {
        const data = await getDocuments(userInfo);
        const hasWForm = data.some((doc) => isTaxDocument(doc.documentType));
        if (hasWForm) navigate(`${basePath}/tax-center`);
      } catch {
        setError(true);
      }
    }
    validateTaxForms();
  }, []);

  useEffect(() => {
    if (
      currentForm === null &&
      !isLoading
    ) {
      /**
       * W9 form is only for US persons and US entities
       * W8BEN form is only for non-US persons
       * W8BENE form is only for non-US entities
       */
      if (hasUSTax && !hasForeignTax) {
        track(TrackingType.TA_PAYOUTS_W9_FORWARD_PAGE_1);
        setCurrentForm(TaxFormType.W9);
      } else if (!isEntity && hasForeignTax && !hasUSTax) {
        track(TrackingType.TA_PAYOUTS_W8_FORWARD_PAGE_1);
        setCurrentForm(TaxFormType.W8BEN);
      } else if (isEntity && hasForeignTax && !hasUSTax) {
        track(TrackingType.TA_PAYOUTS_W8BEN_E_FORWARD_PAGE_1);
        setCurrentForm(TaxFormType.W8BENE);
      }
    }
  }, [hasUSTax, hasForeignTax, isEntity, investorInfo, isLoading]);

  if (error || erroredInvestor) return <ErrorPage />;

  const showFormSelector =
    (hasUSTax && hasForeignTax && currentForm !== TaxFormType.W8BENE) ||
    hasNoTax;

  return (
    <>
      {!isVerified && !isLoading && <VerifyProfileModal />}
      {pathname === `${basePath}/tax-center/tax-form` && (
        <WhmButton
          size="medium"
          startIcon={<Close />}
          color="secondary"
          tabIndex={0}
          onClick={() => navigate(`${basePath}/tax-center`)}
          style={{ position: "absolute", top: 0 }}
        >
          {!isMobile && (
            <span className="font-20 ml-2">
              {t(translationKeys.EXIT_BUTTON)}
            </span>
          )}
        </WhmButton>
      )}
      <WhmStack
        component="section"
        spacing={"20px"}
        sx={{ paddingTop: "1rem", paddingBottom: "1.5rem" }}
      >
        <FormHeader
          isLoading={isLoading}
          currentForm={currentForm}
          showFormSelector={Boolean(showFormSelector)}
        />
        {showFormSelector && !isLoading && (
          <FormTypeSelector
            currentForm={currentForm}
            setCurrentForm={setCurrentForm}
            hasUSTax={hasUSTax}
            hasForeignTax={hasForeignTax}
            hasNoTax={hasNoTax}
            isEntity={isEntity}
          />
        )}
        {currentForm === TaxFormType.W9 && (
          <W9TaxForm
            userInfo={userInfo}
            investorInfo={investorInfo}
            isLoading={isLoading}
            disableInputs={isVerified}
          />
        )}
        {currentForm === TaxFormType.W8BEN && (
          <W8BENTaxForm
            userInfo={userInfo}
            investorInfo={investorInfo}
            isLoading={isLoading}
            disableInputs={isVerified}
          />
        )}
        {currentForm === TaxFormType.W8BENE && (
          <W8BENETaxForm
            userInfo={userInfo}
            investorInfo={investorInfo}
            isLoading={isLoading}
          />
        )}
      </WhmStack>
    </>
  );
};

export default TaxForm;
