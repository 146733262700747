import { useFormikContext } from "formik";
import AddressInputsBase from "./AddressInputsBase";

type Props = {
  disabled?: boolean;
  isW9?: boolean;
};

function AddressInputs({ disabled, isW9 }: Props) {
  const formik = useFormikContext<{
    address1: string;
    address2: string;
    city: string;
    postalCode: string;
    country: string;
    region: string;
  }>();

  const errored =
    Boolean(formik.errors.address1) ||
    Boolean(formik.errors.address2) ||
    Boolean(formik.errors.city) ||
    Boolean(formik.errors.postalCode) ||
    Boolean(formik.errors.country) ||
    Boolean(formik.errors.region);

  return (
    <AddressInputsBase
      disabled={disabled}
      errored={errored}
      addressInputName="address1"
      addressOptionalInputName="address2"
      cityInputName="city"
      stateInputName="region"
      postalCodeInputName="postalCode"
      countryInputName="country"
      onlyUS={isW9}
    />
  );
}

export default AddressInputs;
