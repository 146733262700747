import React from "react";
import FormBlock from "./FormBlock";
import { WhmSkeleton } from "@securitize/reactjs-whm";

type Props = {
  children: React.ReactNode;
};

function FormBlockSkeleton({ children }: Props) {
  return (
    <FormBlock
      title={<WhmSkeleton variant="text" width="30%" />}
      description={
        <WhmSkeleton
          variant="text"
          width="100%"
          sx={{
            marginBottom: "2rem !important",
            marginTop: "0.5rem !important",
          }}
        />
      }
    >
      {children}
    </FormBlock>
  );
}

export default FormBlockSkeleton;
