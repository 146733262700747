import React from "react";
import { WhmFlexDataGrid } from "@securitize/reactjs-whm";
import { GridMenu } from "./GridMenu";
import { DocumentsRecord } from "../../../types/documents.interface";
import { CardComponent } from "./MobileCard";
import Translate, {
  TranslateProps,
} from "../../../components/Translation/Translate";
import { DownloadMenuItem } from "./DownloadMenuItem";
import useTranslations from "../../../hooks/useTranslations";
import { translationKeys } from "../../../contexts/I18nContext"; // Import the new component

export const DataGrid = ({
  documents,
  loadServerRows,
}: {
  documents: DocumentsRecord[];
  loadServerRows: () => void;
}) => {
  const { getTranslation: t } = useTranslations();

  const rowsPerPageOptions = [10, 25, 50];
  const columnsVisibility = { id: false };
  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "showDate",
      headerName: t(translationKeys.GRID_HEADER_DATE),
      type: "string",
      flex: 1,
      renderCell: ({ value }: { value: string | TranslateProps }) => {
        if (typeof value === "string") {
          return value;
        } else {
          return (
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            <Translate
              translationKey={value.translationKey}
              replacements={value.replacements}
            />
          );
        }
      },
    },
    {
      field: "showType",
      headerName: t(translationKeys.GRID_HEADER_DOCUMENT_TYPE),
      flex: 1,
    },
    {
      field: "relatedWith",
      headerName: t(translationKeys.GRID_HEADER_RELATED_WITH),
      flex: 1,
      renderCell: ({ value }: { value: string }) => {
        return (
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          <Translate translationKey={value} />
        );
      },
    },
    {
      field: "download",
      headerName: "",
      sortable: false,
      width: 20,
      renderCell: ({ row }: { row: DocumentsRecord }) => (
        <DownloadMenuItem document={row} isMobile={false} />
      ),
    },
    {
      field: "actions",
      sortable: false,
      headerName: "",
      width: 20,
      renderCell: ({ row }: { row: DocumentsRecord }) => (
        <GridMenu row={row} loadServerRows={loadServerRows} />
      ),
    },
  ];

  return (
    // autoHeight property from WhmFlexDataGrid is deprecated
    <div style={{ display: "flex", flexDirection: "column" }}>
      <WhmFlexDataGrid
        localeText={{
          MuiTablePagination: {
            labelRowsPerPage: (
              <Translate
                translationKey={
                  translationKeys.GRID_PAGINATOR_LABEL_ROWS_PER_PAGE
                }
              />
            ),
            labelDisplayedRows: ({ from, to, count }) =>
              // eslint-disable-next-line max-len
              `${from} - ${to} ${t(
                translationKeys.GRID_PAGINATOR_LABEL_DISPLAYED_ROWS,
              )} ${
                count === -1
                  ? `${t(
                      translationKeys.GRID_PAGINATOR_LABEL_DISPLAYED_ROWS_MORE,
                    )} ${to}`
                  : count
              }`,
          },
        }}
        sx={{
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
          border: 0,
          ".MuiDataGrid-columnSeparator": { display: "none" },
        }}
        disableColumnMenu
        disableColumnResize
        // @ts-ignore
        columns={columns}
        // @ts-ignore
        rows={documents}
        rowsPerPageOptions={rowsPerPageOptions}
        clientSide={true}
        initialPage={0}
        initialPageSize={rowsPerPageOptions[1]}
        columnsVisibility={columnsVisibility}
        checkboxSelection={false}
        disableRowSelectionOnClick={true}
        CardComponent={(props) => (
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          <CardComponent row={props.row} loadServerRows={loadServerRows} />
        )}
      />
    </div>
  );
};
