import { createContext, useContext } from "react";

export interface MFEContextType {
  basePath?: string;
  onUpdate?: () => void;
}

export const MFEContext = createContext({} as MFEContextType);

type Props = {
  children: React.ReactNode;
  basePath?: string;
  onUpdate?: () => void;
};

const MFEProvider = ({ children, ...props }: Props) => {
  return <MFEContext.Provider value={props}>{children}</MFEContext.Provider>;
};

export default MFEProvider;

export const useMFEContext = () => {
  return useContext(MFEContext);
};
