import { WhmSkeleton, WhmStack } from "@securitize/reactjs-whm";

function FormHeaderSkeleton() {
  return (
    <WhmStack>
      <WhmSkeleton variant="text" width="30%" />
      <WhmSkeleton
        variant="text"
        width="100%"
        sx={{
          marginBottom: "2rem !important",
          marginTop: "0.5rem !important",
        }}
      />
    </WhmStack>
  );
}

export default FormHeaderSkeleton;
