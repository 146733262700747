import {
  WhmCheckbox,
  WhmFormControl,
  WhmFormControlLabel,
  WhmStack,
} from "@securitize/reactjs-whm";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { translationKeys } from "../../../../contexts/I18nContext";
import useTranslations from "../../../../hooks/useTranslations";
import AddressInputsBase from "./AddressInputsBase";

type Props = {
  disabled?: boolean;
};

function MailingAddressInputs({ disabled }: Props) {
  const [useInvestorAddress, setUseInvestorAddress] = useState(false);
  const { getTranslation: t } = useTranslations();
  const formik = useFormikContext<{
    address1: string;
    address2: string;
    city: string;
    postalCode: string;
    country: string;
    region: string;
    mailingAddress1: string;
    mailingAddress2: string;
    mailingCity: string;
    mailingPostalCode: string;
    mailingCountry: string;
    mailingRegion: string;
  }>();

  useEffect(() => {
    if (useInvestorAddress) {
      formik.setValues({
        ...formik.values,
        mailingAddress1: formik.values.address1,
        mailingAddress2: formik.values.address2,
        mailingCity: formik.values.city,
        mailingPostalCode: formik.values.postalCode,
        mailingCountry: formik.values.country,
        mailingRegion: formik.values.region,
      });
    }
  }, [
    useInvestorAddress,
    formik.values.address1,
    formik.values.address2,
    formik.values.city,
    formik.values.postalCode,
    formik.values.country,
    formik.values.region,
  ]);

  const errored =
    Boolean(formik.errors.mailingAddress1) ||
    Boolean(formik.errors.mailingAddress2) ||
    Boolean(formik.errors.mailingCity) ||
    Boolean(formik.errors.mailingPostalCode) ||
    Boolean(formik.errors.mailingCountry) ||
    Boolean(formik.errors.mailingRegion);

  return (
    <WhmStack direction="column" spacing={6} sx={{paddingTop: '1rem'}}>
      <WhmFormControlLabel
        label={t(translationKeys.FORM_SECTION_MAILING_ADDRESS_CHECKBOX)}
        control={
          <WhmFormControl margin="none">
            <WhmCheckbox
              id="use-personal-address"
              name="use-personal-address"
              checked={useInvestorAddress}
              onChange={() => setUseInvestorAddress((prev) => !prev)}
              sx={{ padding: 0, marginRight: "1rem" }}
            />
          </WhmFormControl>
        }
      />
      <AddressInputsBase
        disabled={disabled}
        errored={errored}
        addressInputName="mailingAddress1"
        addressOptionalInputName="mailingAddress2"
        cityInputName="mailingCity"
        countryInputName="mailingCountry"
        stateInputName="mailingRegion"
        postalCodeInputName="mailingPostalCode"
      />
    </WhmStack>
  );
}

export default MailingAddressInputs;
