import React, { useState } from "react";
import moment from "moment";
import {
  WhmDatePicker,
  WhmDatePickerProps,
  WhmTextField,
} from "@securitize/reactjs-whm";

type Props = {
  id: string;
  name: string;
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: React.FocusEvent<any>) => void;
  error?: boolean;
  helperText?: string | boolean;
  disabled?: boolean;
  inputProps?: Omit<
    WhmDatePickerProps<moment.Moment, moment.Moment>,
    "value" | "onChange" | "onOpen" | "onClose" | "renderInput" | "InputProps"
  >;
};

const DatePicker = WhmDatePicker<moment.Moment, moment.Moment>;

function DatePickerInput(props: Props) {
  const handleDateChange = (date: moment.Moment | null) => {
    const event = {
      target: {
        name: props.name,
        value: date ? date.format("YYYY-MM-DD") : "",
      },
    } as React.ChangeEvent<HTMLInputElement>;
    props.onChange(event);
  };

  return (
    <DatePicker
      label={props.label}
      disableFuture
      autoFocus={false}
      disabled={props.disabled}
      value={moment(props.value, "YYYY-MM-DD")}
      onChange={handleDateChange}
      reduceAnimations
      renderInput={(textInputProps) => (
        <WhmTextField
          id={props.id}
          name={props.name}
          helperText={props.helperText}
          autoFocus={false}
          {...textInputProps}
        />
      )}
      {...(props.inputProps || ({} as object))}
    />
  );
}

export default DatePickerInput;
