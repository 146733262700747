import { useState } from "react";
import * as yup from "yup";
import { Formik } from "formik";
import moment from "moment";
import { SHARED } from "@securitize/domain";
import { KycStatus, UserInfo } from "../../../types/UserInfo";
import { InvestorInfo } from "../../../types/investorInformation.interface";
import { W8BENFormType } from "./FormType";
import {
  addressSchema,
  birthDaySchema,
  claimOfTaxSchema,
  nameConfirmationSchema,
  nameSchema,
  RegionSchema,
  requiredCitizenSchema,
  requiredSchema,
  w8TaxPayerSchema,
} from "./FormValidationSchemas";
import {
  useWhmSnackbar,
  WhmButton,
  WhmFormControl,
  WhmStack,
} from "@securitize/reactjs-whm";
import FormBlock from "./FormBlock";
import PersonalInformationInputs from "./Inputs/PersonalInformationInputs";
import AddressInputs from "./Inputs/AddressInputs";
import FormConfirmationModal from "./FormConfirmationModal";
import MailingAddressInputs from "./Inputs/MailingAddressInputs";
import W8BENTaxInformationInputs from "./Inputs/W8BENTaxInformationInputs";
import ExemptionsInputs from "./Inputs/ExemptionsInputs";
import CertificationInputs from "./Inputs/CertificationInputs";
import { postW8BENTaxForm } from "../../../data/rest/taxForms";
import useTranslations from "../../../hooks/useTranslations";
import { translationKeys } from "../../../contexts/I18nContext";
import useMixPanel, { TrackingType } from "../../../hooks/useMixPanel";
import { TaxFormType } from "../../../types/documents.interface";
import { getInvestorTaxInfo } from "../../../helpers/investor.utils";
import W8BENFormSkeleton from "./W8BENForm.skeleton";
import { useMFEContext } from "../../../contexts/MFEContext";
import useRefreshForm from "../../../hooks/useRefreshForm";

const validationSchema = yup.object({
  ...addressSchema({
    address1: "address1",
    address2: "address2",
    locality: "city",
    postalCode: "postalCode",
  }),
  ...RegionSchema({
    region: "region",
  }),
  ...RegionSchema({
    region: "mailingRegion",
  }),
  ...addressSchema({
    address1: "mailingAddress1",
    address2: "mailingAddress2",
    locality: "mailingCity",
    postalCode: "mailingPostalCode",
  }),
  ...nameSchema({
    name: "formInvestorName",
  }),
  ...w8TaxPayerSchema({
    USTaxPayerId: "USTaxPayerId",
    foreignTaxPayerId: "foreignTaxPayerId",
  }),
  ...nameConfirmationSchema({
    nameConfirmation: "nameConfirmation",
    agree: "agree",
  }),
  ...birthDaySchema({
    birthDay: "birthDay",
  }),
  ...requiredSchema({
    required: "country",
  }),
  ...requiredCitizenSchema({
    required: "countryOfCitizenship",
  }),
  ...claimOfTaxSchema({
    taxCountry: "taxCountry",
  }),
  ...requiredSchema({
    required: "mailingCountry",
  }),
});

type Props = {
  isLoading?: boolean;
  userInfo: UserInfo;
  investorInfo: InvestorInfo;
  disableInputs?: boolean;
};

function W8BENTaxForm({
  isLoading,
  userInfo,
  investorInfo,
  disableInputs,
}: Props) {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState<W8BENFormType | undefined>();
  const snackbar = useWhmSnackbar();
  const track = useMixPanel();
  const { getTranslation: t } = useTranslations();
  const { onUpdate } = useMFEContext();
  const { refresh } = useRefreshForm();

  if (isLoading) return <W8BENFormSkeleton />;

  const onSubmit = async (values: W8BENFormType) => {
    setIsSubmitted(true);
    track(TrackingType.TA_PAYOUTS_W8_SUBMIT);
    await postW8BENTaxForm(
      values,
      ![KycStatus.NOT_VERIFIED, KycStatus.REJECTED].includes(
        userInfo.verificationStatus,
      ),
    );
    snackbar.show(
      t(translationKeys.FORM_FEEDBACK_SUCCESS_DOCUMENT, {
        document: TaxFormType.W8BEN,
      }),
      {
        severity: "success",
      },
    );
    onUpdate?.();
    refresh();
  };

  const isCheckedAndSigned = (values: W8BENFormType) =>
    Boolean(values.agree) && Boolean(values.nameConfirmation);
  const tax = getInvestorTaxInfo(investorInfo, "nonUS");
  const taxUS = getInvestorTaxInfo(investorInfo, "US");

  const initialValues = {
    nameConfirmation: userInfo.fullName || investorInfo.fullName,
    formInvestorName: userInfo.fullName || investorInfo.fullName,
    address1: investorInfo?.address?.street || investorInfo?.address?.houseNumber ? `${investorInfo?.address?.street} ${investorInfo?.address?.houseNumber}` : "",
    address2: investorInfo?.address?.entrance ?? "",
    postalCode: investorInfo?.address?.zip ?? "",
    country: investorInfo?.address?.countryCode ?? "",
    region: investorInfo?.address?.state ?? "",
    city: investorInfo?.address?.city ?? "",
    USTaxPayerId: taxUS.taxPayerIdNumber,
    foreignTaxPayerId: tax.foreignTaxPayerId,
    countryOfCitizenship:
      investorInfo?.address?.countryCode === SHARED.CountryCodes.USA
        ? ""
        : investorInfo?.address?.countryCode,
    mailingAddress1: "",
    mailingAddress2: "",
    mailingAddressAdditionalInfo: "",
    mailingPostalCode: "",
    taxCountry: tax.taxCountry || "",
    mailingCountry: "",
    mailingRegion: "",
    mailingCity: "",
    birthDay: investorInfo.birthday?.date
      ? moment.utc(investorInfo.birthday.date).format("YYYY-MM-DD")
      : "",
    exemptPayeeCodes: "",
    exemptFATCAReportingCodes: "",
    formDate: moment().format("YYYY-MM-DD").toString(),
    agree: false,
  };

  const initialTouched: Record<string, boolean> = Object.keys(
    initialValues,
  ).reduce<Record<string, boolean>>((acc, key) => {
    acc[key] = true;
    return acc;
  }, {});

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        initialTouched={initialTouched}
        onSubmit={(values) => {
          setShowConfirmationModal(true);
          setFormData(values as W8BENFormType);
        }}
      >
        {({ isValid, values, submitForm }) => (
          <WhmFormControl fullWidth>
            <WhmStack spacing={6}>
              <FormBlock
                boxSx={{margin: 0}}
                title={t(translationKeys.FORM_SECTION_PERSONAL_TITLE)}
                description={t(translationKeys.FORM_SECTION_PERSONAL_SUBTITLE)}
              >
                <PersonalInformationInputs
                  variant="full"
                  disabled={disableInputs}
                />
              </FormBlock>
              <FormBlock
                title={t(
                  translationKeys.FORM_W8BEN_SECTION_PERMANENT_ADDRESS_TITLE,
                )}
                description={t(
                  translationKeys.FORM_W8BEN_SECTION_PERMANENT_ADDRESS_SUBTITLE,
                )}
              >
                <AddressInputs disabled={disableInputs} />
              </FormBlock>
              <FormBlock
                title={t(
                  translationKeys.FORM_W8BEN_SECTION_MAILING_ADDRESS_TITLE,
                )}
                description={t(
                  translationKeys.FORM_W8BEN_SECTION_MAILING_ADDRESS_SUBTITLE,
                )}
              >
                <MailingAddressInputs disabled={disableInputs} />
              </FormBlock>
              <FormBlock
                title={t(translationKeys.FORM_SECTION_TAX_INFORMATION_TITLE)}
                description={t(
                  translationKeys.FORM_SECTION_TAX_INFORMATION_SUBTITLE,
                )}
              >
                <W8BENTaxInformationInputs
                  investorInfo={investorInfo}
                  disabled={disableInputs}
                />
              </FormBlock>
              <FormBlock
                title={t(translationKeys.FORM_SECTION_EXEMPTIONS_TITLE)}
                description={t(
                  translationKeys.FORM_SECTION_EXEMPTIONS_SUBTITLE,
                )}
              >
                <ExemptionsInputs />
              </FormBlock>
              <FormBlock
                title={t(translationKeys.FORM_SECTION_CERTIFICATION_TITLE)}
                description={t(
                  translationKeys.FORM_SECTION_CERTIFICATION_SUBTITLE,
                )}
              >
                <CertificationInputs />
              </FormBlock>
              <WhmButton
                variant="contained"
                color="primary"
                sx={{ width: "fit-content" }}
                disabled={
                  !(isValid && isCheckedAndSigned(values as W8BENFormType)) ||
                  isSubmitted
                }
                onClick={submitForm}
              >
                {t(translationKeys.FORM_BUTTONS_SUBMIT)}
              </WhmButton>
            </WhmStack>
          </WhmFormControl>
        )}
      </Formik>
      {Boolean(formData) && showConfirmationModal && (
        <FormConfirmationModal
          open={showConfirmationModal}
          onConfirm={() => onSubmit(formData as W8BENFormType)}
          onClose={() => setShowConfirmationModal(false)}
          onCancel={() => setShowConfirmationModal(false)}
          onError={() => {
            setShowConfirmationModal(false);
            setIsSubmitted(false);
            snackbar.show(t(translationKeys.FORM_FEEDBACK_ERROR), {
              severity: "error",
            });
          }}
        />
      )}
    </div>
  );
}

export default W8BENTaxForm;
