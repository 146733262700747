import { WhmStack, WhmTextField } from "@securitize/reactjs-whm";
import useTranslations from "../../../../hooks/useTranslations";
import { translationKeys } from "../../../../contexts/I18nContext";
import { ThreeColsInputsContainer } from "../InputContainers";
import CountrySelectInput from "./CountrySelectInput";
import StateSelectInput from "./StateSelectInput";
import { useFormikContext } from "formik";
import MissingInformationCallout from "../MissingInformationCallout";

type Props = {
  addressInputName: string;
  addressOptionalInputName: string;
  cityInputName: string;
  stateInputName: string;
  postalCodeInputName: string;
  countryInputName: string;
  disabled?: boolean;
  errored?: boolean;
  onlyUS?: boolean;
};

function AddressInputsBase({ disabled, errored, onlyUS, ...props }: Props) {
  const { getTranslation: t } = useTranslations();
  const formik = useFormikContext<Record<string, string>>();

  return (
    <WhmStack direction="column" spacing={6} sx={{paddingTop: '1rem'}}>
      {errored && <MissingInformationCallout />}
      <ThreeColsInputsContainer
        sx={{
          gridTemplateRows: {
            xs: "unset",
            md: '"1fr 1fr"',
          },
        }}
      >
        <WhmTextField
          disabled={disabled}
          id={props.addressInputName}
          name={props.addressInputName}
          label={t(translationKeys.FORM_SECTION_ADDRESS_STREET)}
          value={formik.values[props.addressInputName]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.errors[props.addressInputName])}
        />
        <WhmTextField
          disabled={disabled}
          id={props.addressOptionalInputName}
          name={props.addressOptionalInputName}
          label={t(translationKeys.FORM_SECTION_ADDRESS_ADDITIONAL)}
          value={formik.values[props.addressOptionalInputName]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.errors[props.addressOptionalInputName])}
        />
        <WhmTextField
          disabled={disabled}
          id={props.cityInputName}
          name={props.cityInputName}
          label={t(translationKeys.FORM_SECTION_ADDRESS_CITY)}
          value={formik.values[props.cityInputName]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.errors[props.cityInputName])}
        />
        <WhmTextField
          disabled={disabled}
          id={props.postalCodeInputName}
          name={props.postalCodeInputName}
          label={t(translationKeys.FORM_SECTION_ADDRESS_ZIP)}
          value={formik.values[props.postalCodeInputName]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.errors[props.postalCodeInputName])}
          helperText={formik.errors[props.postalCodeInputName]}
        />
        <CountrySelectInput
          inputName={props.countryInputName}
          disabled={disabled}
          onlyUS={onlyUS}
        />
        <StateSelectInput
          inputName={props.stateInputName}
          // Should be always enabled
          disabled={false}
          isUSCountry={formik.values[props.countryInputName] === "US"}
        />
      </ThreeColsInputsContainer>
    </WhmStack>
  );
}

export default AddressInputsBase;
