import {
  useMediaQuery,
  WhmDialog,
  WhmDialogContent,
  WhmDialogTitle,
  WhmIconButton,
  WhmTypography,
} from "@securitize/reactjs-whm";
import useExemptionCodes from "../../../hooks/useExemptionCodes";
import { translationKeys } from "../../../contexts/I18nContext";
import useTranslations from "../../../hooks/useTranslations";
import { useTheme } from "@mui/material";

type Props = {
  variant: "PAYEE" | "FATCA";
  open: boolean;
  onClose: () => void;
};

function ExemptCodesModal({ variant, open, onClose }: Props) {
  const { getTranslation: t } = useTranslations();
  const { codesListResolver, itemIndexResolver } = useExemptionCodes();
  const whmTheme = useTheme();

  const dialogTitleResolver: Record<Props["variant"], string> = {
    PAYEE: t(translationKeys.FORM_MODAL_EXEMPTIONS_PAYEE_TITLE),
    FATCA: t(translationKeys.FORM_MODAL_EXEMPTIONS_FATCA_TITLE),
  };
  const isMobile = useMediaQuery(whmTheme.breakpoints.down("xl"));

  return (
    <WhmDialog open={open} maxWidth="lg" fullScreen={isMobile}>
      <WhmDialogTitle>
        <WhmTypography variant="h4">
          {dialogTitleResolver[variant]}
        </WhmTypography>
      </WhmDialogTitle>
      <WhmIconButton
        color="default"
        size="small"
        aria-label="close"
        onClick={onClose}
        sx={(theme) => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <i className={`wh-icon-exit`} />
      </WhmIconButton>
      <WhmDialogContent>
        {codesListResolver[variant].map((text, index) => (
          <WhmTypography gutterBottom variant="body2">
            {`${itemIndexResolver[variant](index)}. ${text}`}
          </WhmTypography>
        ))}
      </WhmDialogContent>
    </WhmDialog>
  );
}

export default ExemptCodesModal;
