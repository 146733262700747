import {
  WhmLink,
  WhmStack,
  whmTheme,
  WhmTypography,
} from "@securitize/reactjs-whm";
import { translationKeys } from "../../../contexts/I18nContext";
import useTranslations from "../../../hooks/useTranslations";
import { TaxFormType } from "../../../types/documents.interface";
import { ISRLinkResolver } from "./FormTypeSelector";
import FormHeaderSkeleton from "./FormHeader.skeleton";

type Props = {
  showFormSelector: boolean;
  currentForm: TaxFormType | null;
  isLoading?: boolean;
};

function FormHeader({ currentForm, showFormSelector, isLoading }: Props) {
  const { getTranslation: t } = useTranslations();

  if (isLoading) return <FormHeaderSkeleton />;

  const formTitle = showFormSelector
    ? t(translationKeys.FORM_HEADER_TITLE)
    : t(translationKeys.FORM_HEADER_TITLE_DOCUMENT, {
        document:
          currentForm === TaxFormType.W8BENE ? "W-8BEN-E" : currentForm || "",
      });

  return (
    <WhmStack>
      <WhmTypography variant="h4">{formTitle}</WhmTypography>
      {showFormSelector || currentForm === TaxFormType.W8BENE ? (
        <WhmTypography
          variant="body2"
          color={whmTheme.palette.text.secondary}
          sx={{
            minHeight: "1.5rem",
            display: "flex",
            alignItems: "center",
          }}
        >
          {t(translationKeys.FORM_HEADER_SUBTITLE)}
        </WhmTypography>
      ) : (
        <WhmTypography
          variant="body2"
          color={whmTheme.palette.text.secondary}
          sx={{
            minHeight: "1.5rem",
          }}
        >
          {t(translationKeys.FORM_HEADER_SUBTITLE_DOCUMENT, {
            document: currentForm ?? "",
          })}{" "}
          <WhmLink
            href={ISRLinkResolver[currentForm!]}
            underline="none"
            variant="body2"
            rel="noopener noreferrer"
            sx={{
              textDecoration: "underline",
              color: `${whmTheme.palette.primary.main} !important`,
            }}
          >
            {t(translationKeys.FORM_HEADER_SUBTITLE_DOCUMENT_LINK)}
          </WhmLink>
        </WhmTypography>
      )}
    </WhmStack>
  );
}

export default FormHeader;
