import { whmTheme, WhmTypography } from "@securitize/reactjs-whm";
import useTranslations from "../../../hooks/useTranslations";
import { translationKeys } from "../../../contexts/I18nContext";

function MissingInformationCallout() {
  const { getTranslation: t } = useTranslations();
  return (
    <WhmTypography variant="caption" color={whmTheme.palette.error.main} sx={{marginTop: '-2rem !important'}}>
      {t(translationKeys.FORM_SECTION_MAILING_ADDRESS_WARNING)}
    </WhmTypography>
  );
}

export default MissingInformationCallout;
