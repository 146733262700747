import {
  WhmStack,
  WhmTypography,
  WhmTextField,
  WhmFormControl,
  WhmCheckbox,
  WhmFormControlLabel,
  WhmBox,
} from "@securitize/reactjs-whm";
import { useFormikContext } from "formik";
import { TwoColsInputsContainer } from "../InputContainers";
import { translationKeys } from "../../../../contexts/I18nContext";
import useTranslations from "../../../../hooks/useTranslations";
import DatePickerInput from "./DatePickerInput";

function CertificationInputs() {
  const { getTranslation: t } = useTranslations();
  const formik = useFormikContext<{
    formInvestorName?: string;
    businessName?: string;
    nameConfirmation: string;
    formDate: string;
    agree: boolean;
  }>();

  return (
    <WhmStack direction="column" spacing={6}>
      <WhmFormControlLabel
        label={
          <WhmBox sx={{ marginLeft: "1rem",marginTop: '1rem' }}>
            <WhmTypography variant="inherit">
              {t(translationKeys.FORM_SECTION_CERTIFICATION_TEXT)}
            </WhmTypography>
          </WhmBox>
        }
        sx={{
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
        control={
          <WhmFormControl
            margin="none"
            sx={{marginTop: '1rem'}}
            error={formik.touched.agree && Boolean(formik.errors.agree)}
          >
            <WhmCheckbox
              id="agree"
              name="agree"
              checked={formik.values.agree}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              sx={{ padding: 0,marginLeft: "0.58rem", marginRight: "1rem" }}
            />
          </WhmFormControl>
        }
      />
      <TwoColsInputsContainer direction="row">
        <WhmTextField
          required
          id="nameConfirmation"
          name="nameConfirmation"
          label={t(translationKeys.FORM_SECTION_CERTIFICATION_SIGNATURE, {
            name:
              formik.values.formInvestorName ||
              formik.values.businessName ||
              "",
          })}
          value={formik.values.nameConfirmation}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.nameConfirmation &&
            Boolean(formik.errors.nameConfirmation)
          }
          helperText={
            formik.touched.nameConfirmation && formik.errors.nameConfirmation
          }
        />
        <DatePickerInput
          id="date-picker"
          name="formDate"
          disabled
          label={t(translationKeys.FORM_SECTION_CERTIFICATION_DATE)}
          value={formik.values.formDate}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.formDate && Boolean(formik.errors.formDate)}
          helperText={formik.touched.formDate && formik.errors.formDate}
        />
      </TwoColsInputsContainer>
    </WhmStack>
  );
}

export default CertificationInputs;
