import { useNavigate } from "react-router-dom";
import useUserInfo from "../../hooks/useUserInfo";
import TranslationKeys from "../../contexts/I18nContext/translationKeys";
import Translate from "../../components/Translation/Translate";
import { DataGrid } from "./components/DataGrid";
import React, { useCallback, useEffect } from "react";
import { getDocuments } from "../../data/rest/documents";
import { DocumentsRecord } from "../../types/documents.interface";
import { isTaxDocument } from "../../common/taxForms.utils";
import {
  useMediaQuery,
  WhmAlert,
  WhmAlertTitle,
  WhmBox,
  WhmButton,
  WhmCard,
  WhmTypography,
} from "@securitize/reactjs-whm";
import { useTheme } from "@mui/material";
import { DataGridSkeleton } from "./components/DataGrid.skeleton";
import { useMFEContext } from "../../contexts/MFEContext";
import "../../scss/styles.scss";
import TaxForm from "../TaxForm";
import { ErrorPage } from "../../components/shared/ErrorPage";

const Dashboard = () => {
  const [documents, setDocuments] = React.useState<DocumentsRecord[] | null>(
    null,
  );
  const [hasTaxForm, setHasTaxForm] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<boolean>(false);
  const { basePath } = useMFEContext();
  const userInfo = useUserInfo();
  const navigate = useNavigate();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    void loadServerRows();
  }, []);

  const loadServerRows = useCallback(async () => {
    try {
      const data = await getDocuments(userInfo);
      setDocuments(data);
      setHasTaxForm(
        data.some((doc: DocumentsRecord) => isTaxDocument(doc.documentType)),
      );
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  if (error) return <ErrorPage />;
  if (documents && !documents.length) return <TaxForm />;

  return (
    <WhmCard
      variant="outlined"
      sx={{
        marginTop: "32px",
        backgroundColor: isMobile ? "#f9f9f9" : "#ffffff",
        paddingLeft: "16px",
        paddingRight: "16px",
        paddingBottom: "8px",
        paddingTop: !hasTaxForm && !loading ? "16px" : "8px",
      }}
    >
      <>
        {!hasTaxForm && !loading && (
          <WhmAlert
            severity={"warning"}
            variant={"standard"}
            sx={{
              marginBottom: "16px",
              ".MuiAlert-action": { marginRight: 0 },
            }}
            action={
              !isMobile && (
                <WhmButton
                  color={"secondary"}
                  size={"medium"}
                  variant={"contained"}
                  sx={{
                    marginBottom: "0",
                    height: "auto !important",
                  }}
                  onClick={() => navigate(`${basePath}/tax-center/tax-form`)}
                >
                  <Translate
                    translationKey={TranslationKeys.TAX_CENTER_ALERT_ACTION}
                  />
                </WhmButton>
              )
            }
          >
            <WhmBox sx={{ flexGrow: 1 }}>
              <WhmAlertTitle>
                <Translate
                  translationKey={TranslationKeys.TAX_CENTER_ALERT_TITLE}
                />
              </WhmAlertTitle>
              <WhmTypography variant="body2">
                <Translate
                  translationKey={TranslationKeys.TAX_CENTER_ALERT_MESSAGE}
                />
              </WhmTypography>
            </WhmBox>
            {isMobile && (
              <WhmButton
                color={"secondary"}
                size={"medium"}
                variant={"contained"}
                sx={{ marginTop: "8px", width: "100%" }}
                onClick={() => navigate(`${basePath}/tax-center/tax-form`)}
              >
                <Translate
                  translationKey={TranslationKeys.TAX_CENTER_ALERT_ACTION}
                />
              </WhmButton>
            )}
          </WhmAlert>
        )}
        {loading ? (
          <DataGridSkeleton />
        ) : (
          <DataGrid
            documents={documents ?? []}
            /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
            loadServerRows={loadServerRows}
          />
        )}
      </>
    </WhmCard>
  );
};

export default Dashboard;
