import {
  WhmFormControl,
  WhmInputLabel,
  WhmSelect,
  WhmMenuItem,
  WhmTextField,
} from "@securitize/reactjs-whm";
import { US_STATES } from "./constants";
import { useFormikContext } from "formik";
import useTranslations from "../../../../hooks/useTranslations";
import { translationKeys } from "../../../../contexts/I18nContext";
import { useEffect, useState } from "react";
import { isValidString } from "../../../../helpers/input.utils";

type Props = {
  inputName?: string;
  inputLabel?: string;
  disabled?: boolean;
  isUSCountry?: boolean;
};

function StateSelectInput({
  inputName = "region",
  inputLabel,
  disabled,
  isUSCountry = false,
}: Props) {
  const [hasInitialValue, setHasInitialValue] = useState(false);
  const { getTranslation: t } = useTranslations();
  const formik = useFormikContext<{ [key: string]: string }>();
  const label = inputLabel || t(translationKeys.FORM_SECTION_ADDRESS_STATE);

  useEffect(() => {
    formik.setTouched({ region: true });
    setHasInitialValue(isValidString(formik.values[inputName]));
  }, []);

  return (
    <WhmFormControl>
      {isUSCountry ? (
        <>
          <WhmInputLabel
            id={`label-${inputName}`}
            error={Boolean(formik.errors[inputName])}
            disabled={disabled || hasInitialValue}
          >
            {label}
          </WhmInputLabel>
          <WhmSelect
            disabled={disabled || hasInitialValue}
            labelId={`label-${inputName}`}
            label={label}
            id={inputName}
            name={inputName}
            value={formik.values[inputName]}
            onBlur={formik.handleBlur}
            onChange={(e) => formik.setFieldValue(inputName, e.target.value)}
            error={Boolean(formik.errors[inputName])}
          >
            {Object.entries(US_STATES).map(([key, value]) => (
              <WhmMenuItem key={key} value={key}>
                {value}
              </WhmMenuItem>
            ))}
          </WhmSelect>
        </>
      ) : (
        <WhmTextField
          id={inputName}
          name={inputName}
          disabled={disabled || hasInitialValue}
          label={inputLabel || t(translationKeys.FORM_SECTION_ADDRESS_STATE)}
          value={formik.values[inputName]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.errors[inputName])}
        />
      )}
    </WhmFormControl>
  );
}

export default StateSelectInput;
