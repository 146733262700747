import FormBlock from "./FormBlock";
import {
  WhmFormControl,
  WhmFormControlLabel,
  WhmLink,
  WhmRadio,
  WhmRadioGroup,
  WhmStack,
  whmTheme,
  WhmTypography,
} from "@securitize/reactjs-whm";
import useTranslations from "../../../hooks/useTranslations";
import { translationKeys } from "../../../contexts/I18nContext";
import { TaxFormType } from "../../../types/documents.interface";

export const ISRLinkResolver: Partial<Record<TaxFormType, string>> = {
  [TaxFormType.W9]: "https://www.irs.gov/pub/irs-pdf/iw9.pdf",
  [TaxFormType.W8BEN]: "https://www.irs.gov/pub/irs-pdf/iw8ben.pdf",
  [TaxFormType.W8BENE]: "https://www.irs.gov/pub/irs-pdf/iw8bene.pdf",
};

type Props = {
  currentForm: TaxFormType | null;
  setCurrentForm: (formType: TaxFormType) => void;
  hasUSTax: boolean;
  hasForeignTax: boolean;
  hasNoTax: boolean;
  isEntity: boolean;
};

function FormTypeSelector({ currentForm, setCurrentForm, ...props }: Props) {
  const { getTranslation: t } = useTranslations();
  return (
    <FormBlock
      title={t(translationKeys.FORM_SELECTOR_TITLE)}
      description={t(translationKeys.FORM_SELECTOR_SUBTITLE)}
    >
      <WhmFormControl sx={{ marginY: "0.5rem" }}>
        <WhmRadioGroup
          row
          name="form-selector-radio-buttons-group"
          value={currentForm}
          onChange={(e) => setCurrentForm(e.target.value as TaxFormType)}
        >
          {props.isEntity && <WhmFormControlLabel
            value={TaxFormType.W8BENE}
            control={<WhmRadio />}
            label={TaxFormType.W8BENE}
            disabled={!props.hasForeignTax && !props.hasNoTax}
          />}
          <WhmFormControlLabel
            value={TaxFormType.W8BEN}
            control={<WhmRadio />}
            label={TaxFormType.W8BEN}
            disabled={!props.hasForeignTax && props.isEntity}
          />
          <WhmFormControlLabel
            value={TaxFormType.W9}
            control={<WhmRadio />}
            label={TaxFormType.W9}
            disabled={!props.hasUSTax && !props.hasNoTax}
          />
        </WhmRadioGroup>
      </WhmFormControl>
      <WhmStack spacing={2}>
        <WhmTypography
          variant="body2"
          sx={(theme) => ({ color: theme.palette.text.secondary })}
        >
          {t(translationKeys.FORM_SELECTOR_W8BEN_DESCRIPTION)}{" "}
          <WhmLink
            href={ISRLinkResolver["W-8BEN"]}
            underline="none"
            variant="body2"
            rel="noopener noreferrer"
            sx={{
              textDecoration: "underline",
              color: `${whmTheme.palette.primary.main} !important`,
            }}
          >
            {t(translationKeys.FORM_HEADER_SUBTITLE_DOCUMENT_LINK)}
          </WhmLink>
        </WhmTypography>
        <WhmTypography
          variant="body2"
          sx={(theme) => ({ color: theme.palette.text.secondary })}
        >
          {t(translationKeys.FORM_SELECTOR_W9_DESCRIPTION)}{" "}
          <WhmLink
            href={ISRLinkResolver["W-9"]}
            underline="none"
            variant="body2"
            rel="noopener noreferrer"
            sx={{
              textDecoration: "underline",
              color: `${whmTheme.palette.primary.main} !important`,
            }}
          >
            {t(translationKeys.FORM_HEADER_SUBTITLE_DOCUMENT_LINK)}
          </WhmLink>
        </WhmTypography>
      </WhmStack>
    </FormBlock>
  );
}

export default FormTypeSelector;
