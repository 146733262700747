import { WhmScopedThemeProvider } from "@securitize/reactjs-whm";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { I18nContextProvider } from "../../contexts/I18nContext";
import { handleAuthentication } from "../../helpers/authHelper";
import { MixPanelType } from "../../types/mixpanel.types";
import MixPanelProvider from "../../contexts/MixPanelContext";
import MFEProvider from "../../contexts/MFEContext";

export interface MFEContainerProps {
  language: string;
  children: React.ReactNode;
  MixPanel: MixPanelType;
  basePath?: string;
  onUpdate?: () => void;
}

export default function MFEContainer({
  children,
  language,
  MixPanel,
  basePath,
  onUpdate,
}: MFEContainerProps) {
  if (process.env.NODE_ENV === "development") {
    void handleAuthentication();
  }
  const queryProvider = new QueryClient({
    defaultOptions: {
      queries: {
        // We don't want to refetch every time the window is focused
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <QueryClientProvider client={queryProvider}>
      <I18nContextProvider language={language}>
        <WhmScopedThemeProvider>
          <MixPanelProvider MixPanel={MixPanel}>
            <MFEProvider basePath={basePath} onUpdate={onUpdate}>
              {children}
            </MFEProvider>
          </MixPanelProvider>
        </WhmScopedThemeProvider>
      </I18nContextProvider>
    </QueryClientProvider>
  );
}
