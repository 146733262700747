import {
  WhmFormControl,
  WhmInputLabel,
  WhmMenuItem,
  WhmSelect,
} from "@securitize/reactjs-whm";
import { useFormikContext } from "formik";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import useTranslations from "../../../../hooks/useTranslations";
import { translationKeys } from "../../../../contexts/I18nContext";
import { COUNTRIES, US_TERRITORIES } from "./constants";

type Props = {
  inputName?: string;
  inputLabel?: string;
  disabled?: boolean;
  onlyUS?: boolean;
};

function CountrySelectInput({
  inputName = "country",
  inputLabel,
  disabled,
  onlyUS,
}: Props) {
  const { getTranslation: t } = useTranslations();
  const formik = useFormikContext<Record<string, string>>();
  const label = inputLabel || t(translationKeys.FORM_SECTION_ADDRESS_COUNTRY);
  return (
    <WhmFormControl>
      <WhmInputLabel
        id={inputName}
        error={Boolean(formik.errors[inputName])}
        disabled={disabled}
      >
        {label}
      </WhmInputLabel>
      <WhmSelect
        disabled={disabled}
        labelId={inputName}
        label={label}
        id={inputName}
        name={inputName}
        value={formik.values[inputName]}
        onBlur={formik.handleBlur}
        onChange={(e) => formik.setFieldValue(inputName, e.target.value)}
        error={Boolean(formik.errors[inputName])}
      >
        {Object.entries(onlyUS ? US_TERRITORIES : COUNTRIES).map(
          ([key, name]) => (
            <WhmMenuItem key={key} value={key}>
              <span
                className={`fi fi-${key.toLowerCase()}`}
                style={{ marginRight: 10 }}
              />
              {name}
            </WhmMenuItem>
          ),
        )}
      </WhmSelect>
    </WhmFormControl>
  );
}

export default CountrySelectInput;
