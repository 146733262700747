import React from "react";
import {
  WhmButton,
  WhmStack,
  whmTheme,
  WhmTypography,
} from "@securitize/reactjs-whm";
import { ReactComponent as Error } from "../../assets/Error.svg";
import { ReactComponent as RefreshIcon } from "@securitize/assets-sec-ui/dist/icons/svg/refresh.svg";
import useTranslations from "../../hooks/useTranslations";
import { translationKeys } from "../../contexts/I18nContext";

interface ErrorPageProps {
  onRefresh?: (() => void) | (() => Promise<any>);
}

export const ErrorPage: React.FC<ErrorPageProps> = ({ onRefresh }) => {
  const { getTranslation: t } = useTranslations();
  const refresh = onRefresh || (() => window.location.reload());

  return (
    <WhmStack sx={{ padding: "24px", alignItems: "center" }}>
      <Error style={{ height: "124px" }} />

      <WhmTypography
        variant={"h4"}
        sx={{ marginTop: "16px", textAlign: "center" }}
      >
        {t(translationKeys.ERROR_TITLE)}
      </WhmTypography>
      <WhmTypography
        variant={"body1"}
        sx={{ textAlign: "center", marginTop: "8px", marginBottom: "24px" }}
      >
        {t(translationKeys.ERROR_DESCRIPTION)}
      </WhmTypography>

      <WhmTypography
        variant={"body1"}
        sx={{
          textAlign: "center",
          fontSize: "14px",
          a: {
            textDecoration: "underline !important",
            color: whmTheme.palette.primary.main,
          },
        }}
      >
        {t(translationKeys.ERROR_FOOTER)}
      </WhmTypography>
      <WhmButton
        onClick={refresh}
        variant="contained"
        color="primary"
        data-test-id="profile-error-cta"
        sx={{ marginTop: "24px" }}
        endIcon={<RefreshIcon fill={whmTheme.palette.primary.contrastText} />}
      >
        {t(translationKeys.ERROR_ACTION)}
      </WhmButton>
    </WhmStack>
  );
};
