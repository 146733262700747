import { useFormikContext } from "formik";
import moment from "moment";
import { WhmStack, WhmTextField } from "@securitize/reactjs-whm";
import { ThreeColsInputsContainer } from "../InputContainers";
import CountrySelectInput from "./CountrySelectInput";
import useTranslations from "../../../../hooks/useTranslations";
import { translationKeys } from "../../../../contexts/I18nContext";
import DatePickerInput from "./DatePickerInput";
import useUserInfo from "../../../../hooks/useUserInfo";
import { ISR } from "@securitize/domain";
import MissingInformationCallout from "../MissingInformationCallout";

type Props = {
  variant?: "name" | "full";
  disabled?: boolean;
};

function PersonalInformationInputs({ variant = "name", disabled }: Props) {
  const user = useUserInfo();
  const { getTranslation: t } = useTranslations();
  const formik = useFormikContext<{
    formInvestorName: string;
    birthDay: string;
    countryOfCitizenship: string;
  }>();

  const isEntity = user?.investorType === ISR.InvestorTypes.ENTITY;

  const errored =
    variant === "full"
      ? Boolean(formik.errors.formInvestorName) ||
        Boolean(formik.errors.birthDay) ||
        Boolean(formik.errors.countryOfCitizenship)
      : Boolean(formik.errors.formInvestorName);

  return (
    <WhmStack direction="column" spacing={6} sx={{paddingTop: '1rem'}}>
      {errored && <MissingInformationCallout />}
      <ThreeColsInputsContainer >
        <WhmTextField
          required
          disabled={disabled}
          id="formInvestorName"
          name="formInvestorName"
          label={t(translationKeys.FORM_SECTION_PERSONAL_INVESTOR_NAME)}
          value={formik.values.formInvestorName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.formInvestorName &&
            Boolean(formik.errors.formInvestorName)
          }
          helperText={
            formik.touched.formInvestorName && formik.errors.formInvestorName
          }
          sx={{ width: "100%", gridColumn: variant === "name" ? "1 / 4" : 1 }}
        />
        {variant === "full" && (
          <>
            <CountrySelectInput
              disabled={disabled}
              inputName="countryOfCitizenship"
              inputLabel={t(translationKeys.FORM_SECTION_PERSONAL_CITIZENSHIP)}
            />
            <DatePickerInput
              disabled={disabled}
              id="date-picker"
              name="birthDay"
              label={t(translationKeys.FORM_SECTION_PERSONAL_BIRTHDATE)}
              value={formik.values.birthDay}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.birthDay && Boolean(formik.errors.birthDay)}
              inputProps={{
                maxDate: isEntity
                  ? moment()
                  : moment().subtract(18, "years").subtract(1, "days"),
              }}
            />
          </>
        )}
      </ThreeColsInputsContainer>
    </WhmStack>
  );
}

export default PersonalInformationInputs;
