import { ISR, SHARED } from "@securitize/domain";
import { useEffect, useState } from "react";
import { getInvestorInformation } from "../data/rest/investorInfo";
import {
  InvestorInfo,
  InvestorInfoTax,
} from "../types/investorInformation.interface";
import useUserInfo from "./useUserInfo";
import { KycStatus } from "../types/UserInfo";

function useInvestorInfo() {
  const user = useUserInfo();
  const [investorInfo, setInvestorInfo] = useState<InvestorInfo | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    async function fetchInvestorInfo() {
      const data = await getInvestorInformation();
      setInvestorInfo(data ?? null);
    }
    if (user && investorInfo === undefined) {
      try {
        fetchInvestorInfo();
      } catch {
        setError(true);
      } finally {
        setLoading(false);
      }
    }
  }, [user, investorInfo, setInvestorInfo]);

 const isEntity = user?.investorType === ISR.InvestorTypes.ENTITY;

  const verificationStatus = user.verificationStatus;
  const  isVerified =  ![KycStatus.NOT_VERIFIED, KycStatus.REJECTED].includes(verificationStatus)


  if (
    !investorInfo
  ) {
    const initialInvestorInfo = {
      fullName: "",
      birthday: {
        date: "",
        city: "",
        country: "",
        state: "",
      },
      address: {
        street: "",
        houseNumber: "",
        entrance: "",
        city: "",
        countryCode: "",
        state: "",
        zip: "",
      },
      tax: [],
    };
    return {
      isVerified: false,
      hasUSTax: false,
      hasForeignTax: false,
      hasNoTax: true,
      isEntity,
      investorInfo: initialInvestorInfo,
      userInfo: user,
      error: false,
    };
  } else {
    const filteredInvestorInfo = { ...investorInfo };
    const hasUSTax =
      investorInfo.tax.filter(
        (tax: InvestorInfoTax) =>
          tax.taxCountryCode && SHARED.isUsTerritory(tax.taxCountryCode),
      ).length > 0 ;

    const hasForeignTax =
      investorInfo.tax.filter(
        (tax: InvestorInfoTax) =>
          tax.taxCountryCode && !SHARED.isUsTerritory(tax.taxCountryCode),
      ).length > 0;

    const hasNoTax = !hasUSTax && !hasForeignTax;
    return {
      isVerified,
      isLoading: loading,
      hasUSTax,
      hasNoTax,
      hasForeignTax: Boolean(hasForeignTax),
      isEntity,
      investorInfo: filteredInvestorInfo,
      userInfo: user,
      error,
    };
  }
}

export default useInvestorInfo;
