import {
  WhmFormControl,
  WhmInputLabel,
  WhmSelect,
  WhmMenuItem,
  WhmTextField,
  WhmTypography,
  WhmBox,
  WhmStack,
} from "@securitize/reactjs-whm";
import { useFormikContext } from "formik";
import { ThreeColsInputsContainer } from "../InputContainers";
import { InvestorType, TaxIdType } from "../../../../types/documents.interface";
import useTranslations from "../../../../hooks/useTranslations";
import { translationKeys } from "../../../../contexts/I18nContext";
import MissingInformationCallout from "../MissingInformationCallout";

export const entityClassification: Record<string, string> = {
  sole: translationKeys.FORM_W9_SECTION_TAX_CLASSIFICATION_SOLE,
  single: translationKeys.FORM_W9_SECTION_TAX_CLASSIFICATION_SINGLE,
  partnership: translationKeys.FORM_W9_SECTION_TAX_CLASSIFICATION_PARTNERSHIP,
  trust: translationKeys.FORM_W9_SECTION_TAX_CLASSIFICATION_TRUST,
  llc_c_corporation: translationKeys.FORM_W9_SECTION_TAX_CLASSIFICATION_LLC_C,
  llc_s_corporation: translationKeys.FORM_W9_SECTION_TAX_CLASSIFICATION_LLC_S,
  llc_partnership:
    translationKeys.FORM_W9_SECTION_TAX_CLASSIFICATION_LLC_PARTNERSHIP,
  s_corporation:
    translationKeys.FORM_W9_SECTION_TAX_CLASSIFICATION_S_CORPORATION,
  c_corporation:
    translationKeys.FORM_W9_SECTION_TAX_CLASSIFICATION_C_CORPORATION,
};

type Props = {
  investorType: InvestorType;
  disabled?: boolean;
};

function W9TaxInformationInputs({ investorType, disabled }: Props) {
  const { getTranslation: t } = useTranslations();
  const formik = useFormikContext<{
    formClassification: InvestorType;
    taxPayerIdType: string;
    taxPayerIdNumber: string;
  }>();

  const errored =
    Boolean(formik.errors.formClassification) ||
    Boolean(formik.errors.taxPayerIdType) ||
    Boolean(formik.errors.taxPayerIdNumber);

  return (
    <WhmStack direction="column" spacing={6} sx={{paddingTop: '1rem'}}>
      {errored && <MissingInformationCallout />}
      <ThreeColsInputsContainer direction="row">
        <WhmFormControl>
          <WhmInputLabel
            id="formClassification"
            error={
              disabled
                ? Boolean(formik.errors.formClassification)
                : formik.touched.formClassification &&
                  Boolean(formik.errors.formClassification)
            }
          >
            {t(translationKeys.FORM_SECTION_TAX_INFORMATION_CLASSIFICATION)}
          </WhmInputLabel>
          <WhmSelect
            disabled={investorType === InvestorType.INDIVIDUAL}
            labelId="formClassification"
            label={t(
              translationKeys.FORM_SECTION_TAX_INFORMATION_CLASSIFICATION,
            )}
            id="formClassification"
            name="formClassification"
            value={formik.values.formClassification}
            onBlur={formik.handleBlur}
            onChange={(e) =>
              formik.setFieldValue("formClassification", e.target.value)
            }
          >
            {investorType === InvestorType.INDIVIDUAL ? (
              <WhmMenuItem value={InvestorType.INDIVIDUAL}>
                {t(
                  translationKeys.FORM_SECTION_TAX_INFORMATION_CLASSIFICATION_INDIVIDUAL,
                )}
              </WhmMenuItem>
            ) : (
              Object.keys(entityClassification).map((key) => (
                <WhmMenuItem value={key}>
                  {t(entityClassification[key])}
                </WhmMenuItem>
              ))
            )}
          </WhmSelect>
        </WhmFormControl>
        <WhmFormControl>
          <WhmInputLabel
            id="taxPayerIdType"
            error={
              disabled
                ? Boolean(formik.errors.taxPayerIdType)
                : formik.touched.taxPayerIdType &&
                  Boolean(formik.errors.taxPayerIdType)
            }
          >
            {t(translationKeys.FORM_SECTION_TAX_INFORMATION_TAX_IDENTIFIER)}
          </WhmInputLabel>
          <WhmSelect
            disabled={disabled}
            labelId="taxPayerIdType"
            label={t(
              translationKeys.FORM_SECTION_TAX_INFORMATION_TAX_IDENTIFIER,
            )}
            id="taxPayerIdType"
            name="taxPayerIdType"
            value={formik.values.taxPayerIdType}
            onBlur={formik.handleBlur}
            onChange={(e) =>
              formik.setFieldValue("taxPayerIdType", e.target.value)
            }
          >
            <WhmMenuItem value={TaxIdType.SSN}>
              {t(
                translationKeys.FORM_SECTION_TAX_INFORMATION_TAX_IDENTIFIER_SSN,
              )}
            </WhmMenuItem>
            <WhmMenuItem value={TaxIdType.FOREIGN}>
              {t(
                translationKeys.FORM_SECTION_TAX_INFORMATION_TAX_IDENTIFIER_FOREIGN,
              )}
            </WhmMenuItem>
          </WhmSelect>
        </WhmFormControl>
        <WhmTextField
          disabled={disabled}
          id="taxPayerIdNumber"
          name="taxPayerIdNumber"
          label={t(
            translationKeys.FORM_W8BEN_SECTION_TAX_INFORMATION_US_TAX_ID,
          )}
          value={formik.values.taxPayerIdNumber}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            disabled
              ? Boolean(formik.errors.taxPayerIdNumber)
              : formik.touched.taxPayerIdNumber &&
                Boolean(formik.errors.taxPayerIdNumber)
          }
          helperText={
            formik.touched.taxPayerIdNumber &&
            Boolean(formik.errors.taxPayerIdNumber) ? (
              formik.errors.taxPayerIdNumber
            ) : (
              <WhmBox sx={{ paddingLeft: "1rem" }}>
                <WhmTypography variant="inherit">
                  {t(
                    translationKeys.FORM_SECTION_TAX_INFORMATION_TAX_ID_CALLOUT,
                  )}
                </WhmTypography>
              </WhmBox>
            )
          }
        />
      </ThreeColsInputsContainer>
    </WhmStack>
  );
}

export default W9TaxInformationInputs;
