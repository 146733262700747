import { useLocation, useNavigate } from "react-router";
import { useMFEContext } from "../contexts/MFEContext";

function useRefreshForm() {
  const { basePath } = useMFEContext();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return {
    refresh: () => {
      if (pathname.endsWith("/tax-center")) {
        // eslint-disable-next-line no-restricted-globals
        window.location.reload()
      } else {
        navigate(`${basePath}/tax-center`);
      }
    },
  };
}

export default useRefreshForm;
