import { Navigate, RouteObject } from "react-router";
import Dashboard from "./views/Dashboard";
import TaxForm from "./views/TaxForm";

export const getRoutes = (basePath: string = ""): RouteObject[] => [
  {
    path: `${basePath}/`,
    element: <Navigate to="/profile/tax-center" replace />,
  },
  {
    path: `${basePath}/tax-center`,
    children: [
      { path: "", element: <Dashboard /> },
      { path: "tax-form", element: <TaxForm /> },
    ],
  },
];
