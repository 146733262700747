import { BoxOwnProps, Theme } from "@mui/system";
import {
  WhmCard,
  WhmStack,
  whmTheme,
  WhmTypography,
} from "@securitize/reactjs-whm";
import React from "react";

type Props = {
  title: string | React.ReactNode;
  description?: string | React.ReactNode;
  children: React.ReactNode;
  boxSx?: BoxOwnProps<Theme>["sx"];
  titleSx?: BoxOwnProps<Theme>["sx"];
  descriptionSx?: BoxOwnProps<Theme>["sx"];
};

export const FormBlockDescription = ({
  children,
  sx,
}: {
  children: React.ReactNode;
  sx?: BoxOwnProps<Theme>["sx"];
}) => (
  <WhmTypography
    variant="body2"
    color={whmTheme.palette.text.secondary}
    sx={{
      marginBottom: "1rem !important",
      marginTop: "0.5rem !important",
      ...(sx as object),
    }}
  >
    {children}
  </WhmTypography>
);

function FormBlock({
  title,
  description,
  children,
  boxSx = {},
  titleSx = {},
  descriptionSx = {},
}: Props) {
  return (
    <WhmCard
      variant="outlined"
      sx={{
        width: "100%",
        padding: "1.5rem",
        ...(boxSx as object),
      }}
    >
      <WhmStack sx={{ marginBottom: children ? "1rem" : undefined }}>
        {typeof title === "string" ? (
          <WhmTypography variant="subtitle1" sx={titleSx}>
            {title}
          </WhmTypography>
        ) : (
          title
        )}
        {Boolean(description) &&
          (typeof description === "string" ? (
            <WhmTypography
              variant="body2"
              color={whmTheme.palette.text.secondary}
              sx={descriptionSx}
            >
              {description}
            </WhmTypography>
          ) : (
            description
          ))}
      </WhmStack>
      {children}
    </WhmCard>
  );
}

export default FormBlock;
